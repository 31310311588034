@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    font-family: "Nunito Sans", sans-serif;
    
}
/* html, body {
  height: 100%;
  margin: 0;
  padding: 0;
} */


.smalltitle {
     

font-weight: 700;
/* line-height: 32px; */
font-size: 24px;

}
.footertitle {
     

font-weight: 700;
line-height: 20px;
font-size: 16px;

}

.paragraph {
    font-size: 16px;
    line-height: 22px;
    
    
}

.text-stroke {
    -webkit-text-stroke: 1px #FF7D3B; /* Stroke width and color */
    color: transparent; /* Makes the fill color transparent */
  }

  .text-shadow-stroke {
    color: white; /* Text fill color */
    text-shadow: 
      -1px -1px 0 #FF7D3B,  
      1px -1px 0 #FF7D3B,
      -1px 1px 0 #FF7D3B,
      1px 1px 0 #FF7D3B; /* Creates the stroke effect */
  }


  input:focus {
    outline: none;
    border-color: #FF7D3B; /* Change border color when focused */
    box-shadow: 0 0 5px rgba(255, 125, 59, 0.5); /* Add a subtle shadow */
  }
  
  .marquee {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: #FF7D3B; /* Orange background */
  }
  
  .marquee-content {
    display: inline-block;
    padding-left: 100%; /* Start off-screen */
    animation: marquee 10s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translate(0);
    }
    100% {
      transform: translate(-100%); /* Move left */
    }
  }
  
  /* Set the scrollbar width to 10px */
  .scrollbar {
  
    ::-webkit-scrollbar {
      width: 2px;
     
    }
  }



  .sidebar {
    transition: transform 0.5s ease-in-out;
  }
  
  .sidebar-hidden {
    transform: translateX(-100%);
  }
  
  .sidebar-visible {
    transform: translateX(0);
  }
  


 /* Spinner.css */
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

@keyframes sk-bounce {
  0%, 100% { transform: scale(0.0) }
  50% { transform: scale

  }}



  /* Add this CSS to your styles */
.hidden {
  display: none;
}

.dropdown-open {
  display: block;
  animation: slideIn 0.5s ease-in forwards;
}

.dropdown-close {
  animation: slideOut 0.5s ease-in forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(-10);
  }
  to {
    opacity: 0;
    transform: translateY(0);
  }
}


/* Set the scrollbar width to 10px for Firefox */
/* scrollbar-width: thin; */




.slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.custom-dot-group {
  text-align: center;
  /* margin-top: 1px; */
}

.custom-dot-group button {
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  cursor: pointer;
}

.custom-dot-group button:active,
.custom-dot-group button:focus {
  outline: none;
}

.custom-dot-group .carousel__dot--selected {
  background-color: #FF7D3B;
}



.loginBg{

  /* height: 100vh;
  width: 100%; */
   /* background-repeat: no-repeat;
  background-size: cover; */
  background-color: '#FFF3ED';
  /* background-color: '#FF7D3B'; */

  /* left: 50%;
  top: 50%; */
  margin: 0 auto;
  position: relative;
  /* transform: translate(-50%, -50%); */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  /* padding: 20px 27px 128.5px; */
  
  height: auto;
  border-radius: 30px;
  margin-bottom: 200px;
}


/* .loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #FF7D3B;
  box-shadow: 0 0 0 0 #0004;
  animation: l1 1s infinite;
}
@keyframes l1 {
    100% {box-shadow: 0 0 0 30px #0000}
} */

/* HTML: <div class="loader"></div> */
.loader {
  width: 70px;
  --b: 8px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #FF7D3B) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}




.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1000;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* HTML: <div class="loader"></div> */
.loader_bar {
  width: 120px;
  height: 20px;
  border-radius: 20px;
  background:
   linear-gradient(#FF7D3B 0 0) 0/0% no-repeat
   lightblue;
  animation: l2 2s infinite steps(10);
}
@keyframes l2 {
    100% {background-size:110%}
}



